import React, { useContext, useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./TestimonialSlider.scss"
import { navigate } from "gatsby"
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg"
import { ReactComponent as RightArrow } from "../../assets/icons/right-arrow.svg"
import { LanguageContext } from "../../contexts/languageContext"
import { translateText } from "../../utils/translateText"
import { LANGUAGES } from "../../constants"

/**
 * TestimonialSlider component.
 *
 * @param {Object} props
 * @param {Object} props.children Defines the array of components used as slides.
 * @param {string} props.title Defines the title displyed in the header of component.
 * @param {boolean} props.chyron Defines if the component is diplaying testimonials or chyron sub-components.
 * @return {string} TestimonialSlider component.
 */

const TestimonialSlider = props => {
  const languageContext = useContext(LanguageContext)
  const currentLanguage = languageContext[0]
  const googleSheetData = languageContext[2]

  const routePrefix =
    currentLanguage.label === LANGUAGES.ENGLISH.label ? "" : "/de"

  const leftRef = useRef()
  const rightRef = useRef()
  const center =
    (typeof window !== "undefined" && window.innerWidth < 769) || props.chyron
      ? false
      : true // display type depending on the screen width

  const { children } = props
  let content = !children
    ? null
    : children.map((child, index) => {
        return (
          <div key={index} id={`${index}`} className={"slider-item-container"}>
            {child}
          </div>
        )
      })

  let responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1300 },
      items: 1.4,
    },
    tablet: {
      breakpoint: { max: 1300, min: 980 },
      items: 1,
    },
    tabletSmall: {
      breakpoint: { max: 980, min: 767 },
      items: 0.6,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  }

  if (props.chyron) {
    responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 8,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1199 },
        items: 8,
      },
      laptop: {
        breakpoint: { max: 1199, min: 1024 },
        items: 6,
      },
      tablet: {
        breakpoint: { max: 1024, min: 575 },
        items: 4,
      },
      mobile: {
        breakpoint: { max: 575, min: 455 },
        items: 3,
      },
      mobileNarrow: {
        breakpoint: { max: 455, min: 0 },
        items: 2,
      },
    }
  }

  const CustomRightArrow = ({ onClick, ...rest }) => {
    // onMove means if dragging or swiping in progress.
    return (
      <button
        ref={ref => (rightRef.current = ref)}
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right actual-arrow"
        onClick={() => onClick()}
      >
        {" "}
      </button>
    )
  }

  const CustomLeftArrow = ({ onClick, ...rest }) => {
    // onMove means if dragging or swiping in progress.
    return (
      <button
        ref={ref => (leftRef.current = ref)}
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left actual-arrow"
        onClick={() => onClick()}
      >
        {" "}
      </button>
    )
  }

  const slideLeft = () => {
    if (leftRef.current) {
      leftRef.current.click()
    }
  }

  const slideRight = () => {
    if (leftRef.current) {
      rightRef.current.click()
    }
  }

  const seeAllButtonRef = useRef()

  const innerWidth = typeof window !== "undefined" && window?.innerWidth

  // Different 'seeAllButton' text dependent on screen resolution
  useEffect(() => {
    if (typeof window !== "undefined" && seeAllButtonRef.current) {
      const currentWidth = window.innerWidth
      if (currentWidth <= 409) {
        seeAllButtonRef.current.innerText = `${translateText(
          "See all",
          currentLanguage.fullTitle,
          googleSheetData
        )}`
      }
    }
  }, [innerWidth, currentLanguage.fullTitle, googleSheetData])

  return (
    <div
      className={
        !props.chyron ? "testimonials-container" : "chyron-slider-container"
      }
    >
      {!props.chyron && (
        <h2>{`${translateText(
          "Testimonials",
          currentLanguage.fullTitle,
          googleSheetData
        )}`}</h2>
      )}
      <Carousel
        autoPlay={true}
        responsive={responsive}
        centerMode={center}
        infinite={true}
        itemClass="carousel-item-padding-40-px"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        customTransition={props.chyron ? "all 1s linear" : undefined}
        autoPlaySpeed={props.chyron ? 1 : undefined}
        transitionDuration={props.chyron ? 1000 : undefined}
      >
        {content}
      </Carousel>
      {!props.chyron && (
        <div className="slider-footer">
          <button
            className="dummy-arrow arrow-left"
            onClick={() => slideLeft()}
          >
            <LeftArrow />
          </button>
          <button
            ref={seeAllButtonRef}
            className="btn btn-outline-danger"
            onClick={() => navigate(`${routePrefix}/testimonials`)}
          >
            {`${translateText(
              "See testimonials",
              currentLanguage.fullTitle,
              googleSheetData
            )} →`}
          </button>
          <button
            className="dummy-arrow arrow-right"
            onClick={() => slideRight()}
          >
            <RightArrow />
          </button>
        </div>
      )}
    </div>
  )
}

export default TestimonialSlider
